<script
    lang="ts"
    setup
>
    type Props = {
        left?: boolean
    }

    const props = defineProps<Props>()

    const { maxTablet } = useWindowSize()

    const transitionName = computed<string | undefined>(() => {
        if (!maxTablet.value) {
            return
        }

        return props.left
            ? 'slide-x-left-transition'
            : 'slide-x-right-transition'
    })
</script>

<template>
    <Transition :name="transitionName">
        <slot />
    </Transition>
</template>
